import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
// Vue 3rd party plugins
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/clipboard";
import "@/core/plugins/quil";
import lang from '@/core/lang.json';
import moment from 'moment';
moment.locale("fr");
import "@/core/plugins/leaflet";
Vue.config.productionTip = false
import Api from '@/core/Api';
Vue.prototype.$api = Api;
Api.moment = moment;
Api.text = lang;
Vue.prototype.$api = Api;
new Vue({
  router,  
  render: h => h(App),
  beforeMount(){
    Api.toast = this.$bvToast;        
  }
}).$mount('#app')
