<template>
  <router-view />
</template>
<script>

export default {
  name: "App",  
  beforeMount() {    
  },  
}
</script>
<style lang="scss">
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";

@import "assets/sass/style.vue";
@import "assets/sass/layout";
</style>
