import Vue from "vue";
import {LMap, LTileLayer, LMarker, LPolygon, LGeoJson, LTooltip, LPopup} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-polygon', LPolygon);
Vue.component('l-polygon', LPolygon);
Vue.component('l-geo-json', LGeoJson);
Vue.component('l-tooltip', LTooltip);
Vue.component('l-popup', LPopup);
