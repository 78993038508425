import Vue from "vue";
import VueRouter from "vue-router";
import Api from '@/core/Api';
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: '/statistique',
    component: () => import( "@/views/layout/Main.vue"),
    children:[            
      {
        path:'/param',        
        component: () => import( "@/views/param/Main.vue"),
        children:[
          {
            path:'liste',
            name: 'Parametrage',
            component: () => import( "@/views/param/Liste.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'model/:name',
            name: 'Model',
            component: () => import( "@/views/param/Model.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'quartier',
            name: 'Quartier',
            component: () => import( "@/views/param/Quartier.vue"),
            meta:{
              logged:true
            }
          }          
        ]
      },
      {
        path: "/statistique",
        name: "statistique",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[            
          {
            path: "",
            name: "statistique-liste",
            component: () => import("@/views/statistique/Main.vue"),
            meta: {              
              logged: true
            }
          }          
        ]
      },
      {
        path:'/utilisateur',
        name: 'Utilisateur',
        component: () => import( "@/views/utilisateur/Main.vue"),
        children:[
          {
            path:'nouveau',
            name:'Nouvelle utilisateur',
            component: () => import( "@/views/utilisateur/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'detail/:id',
            name:'Detail de l\'utilisateur',
            component: () => import( "@/views/utilisateur/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'liste',
            name:'Liste des utilisateurs',
            component: () => import( "@/views/utilisateur/Liste.vue"),
            meta:{
              logged:true
            }
          },          
        ]
      },  
      {
        path:'/contact',
        name: 'Contact',
        component: () => import( "@/views/contact/Main.vue"),
        children:[
          {
            path:'nouveau',
            name:'Nouveau contact',
            component: () => import( "@/views/contact/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'detail/:id',
            name:'Detail d\'un contact',
            component: () => import( "@/views/contact/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'liste/:id',
            name:'Liste des contacts',
            component: () => import( "@/views/contact/Liste.vue"),
            meta:{
              logged:true
            }
          },          
        ]
      },
      {
        path:'/wa',
        name: 'WA',
        component: () => import( "@/views/wa/Main.vue"),
        children:[
          {
            path:'import',
            name:'WA import',
            component: () => import( "@/views/wa/Import.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'liste',
            name:'WA liste',
            component: () => import( "@/views/wa/Liste.vue"),
            meta:{
              logged:true
            }
          },
        ]
      },
      {
        path:'/propriete',
        name: 'Propriete',
        component: () => import( "@/views/propriete/Main.vue"),
        children:[
          {
            path:'nouveau',
            name:'Nouvelle propriete',
            component: () => import( "@/views/propriete/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'detail/:id',
            name:'Detail d\'une propriete',
            component: () => import( "@/views/propriete/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'liste',
            name:'Liste des proprietes',
            component: () => import( "@/views/propriete/Liste.vue"),
            meta:{
              logged:true
            }
          },          
          {
            path:'map',
            name:'Carte des proprietes',
            component: () => import( "@/views/propriete/Map.vue"),
            meta:{
              logged:true
            }
          },          
        ]
      },      
      {
        path:'/projet',
        name: 'projet',
        component: () => import( "@/views/projet/Main.vue"),
        children:[
          {
            path:'nouveau',
            name:'Nouvelle projet',
            component: () => import( "@/views/projet/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'detail/:id',
            name:'Detail d\'une projet',
            component: () => import( "@/views/projet/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'liste',
            name:'Liste des projets',
            component: () => import( "@/views/projet/Liste.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'map',
            name:'Carte des projets',
            component: () => import( "@/views/projet/Map.vue"),
            meta:{
              logged:true
            }
          },          
        ]
      },      
      {
        path:'/transaction',
        name: 'projet',
        component: () => import( "@/views/transaction/Main.vue"),
        children:[
          {
            path:'nouveau',
            name:'Nouvelle projet',
            component: () => import( "@/views/transaction/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'detail/:id',
            name:'Detail d\'une projet',
            component: () => import( "@/views/transaction/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'liste',
            name:'Liste des projets',
            component: () => import( "@/views/transaction/Liste.vue"),
            meta:{
              logged:true
            }
          },          
        ]
      },      
      {
        path:'/agenda',
        name: 'Agenda',
        component: () => import( "@/views/agenda/Main.vue"),
        children:[
          {
            path:'mois',
            name:'Agenda par mois',
            component: () => import( "@/views/agenda/Mois.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'semaine',
            name:'Agenda par semaine',
            component: () => import( "@/views/agenda/Semaine.vue"),
            meta:{
              logged:true
            }
          }
        ]
      },      
    ]
  },  
  {
    path:'/login',
    name: 'Login',
    component: () => import( "@/views/Login.vue"),
    meta:{
      logged:false
    }
  },  
  {
    path:'/p/:uuid',
    name: 'ProprieteView',
    component: () => import( "@/views/propriete/View.vue"),
    meta:{
      logged:false
    }
  },  
  { 
    path: "*",
    redirect: "/agenda/mois"
  },
];
async function backVerify(){
  var option = {
    "headers":{
      "Content-Type":"application/json",
      "Authorization": Api.getToken()
    }
  };
  var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  var url = Api.url.api + '/authorization/verify?timezone=' + tz;
  var response = await fetch(url,option);
  var res = await response.json();  
  return res;
}
async function getParams(){
  var option = {
    "headers":{
      "Content-Type":"application/json",
      "Authorization": Api.getToken()
    }
  };
  var url = Api.url.api + '/param/all'
  var response = await fetch(url,option);
  var res = await response.json();
  return res;
}
const router = new VueRouter({
  routes,
});
router.beforeEach(async (to, from, next) => {
  if(to.meta.logged === true){
    if(Api.params === false && Api.getToken() != false){
      await getParams().then(res => {
        if(res.status){
          Api.params = res.data.params;
          Api.menu = res.data.menu
        }
      });
    }
    if(Api.getToken() != false){
      await backVerify().then(res => {
        if(res.status === true){
          Api.saveToken(res.data.token);
          Api.saveUser(res.data);
          next();
        }else{
          next({path:'/login', replace:true})
        }
      }).catch( () => {
        next({path:'/login'})
      });
    }else{
      next({path:'/login'})
    }
  }else{
    next();
  }
})

export default router;
